define("tmp-for-all/models/domain", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var hasMany = _emberData.default.hasMany;
  var _default = _emberData.default.Model.extend({
    featureFlags: hasMany('feature-flag', {
      polymorphic: true
    }),
    featureEnabled: function featureEnabled(flagName) {
      return get(this, 'featureFlags').then(function (ffs) {
        return ffs.find(function (ff) {
          return ff.flagName === flagName;
        });
      }).then(function (ff) {
        return ff !== undefined && ff.isEnabled;
      });
    },
    featureDisabled: function featureDisabled(flagName) {
      return get(this, 'featureFlags').then(function (ffs) {
        var ff = ffs.find(function (ff) {
          return ff.flagName === flagName;
        });
        if (ff) {
          // If the flag is defined but with 'isEnabled'=FALSE, it is disabled
          if (ff.isEnabled) return false;else return true;
        } else {
          // If the flag is not defined at all, it is disabled
          return true;
        }
      });
    }
  });
  _exports.default = _default;
});