define("tmp-for-all/components/if-feature-enabled/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IfFeatureEnabledComponent = Ember.Component.extend({
    tagName: '',
    authorization: Ember.inject.service(),
    store: Ember.inject.service(),
    domain: Ember.computed('authorization', 'domainId', 'domainType', function () {
      var _this = this;
      return Promise.all([Ember.get(this, 'domainType'), Ember.get(this, 'domainId')]).then(function (res) {
        var domainType = res[0];
        var domainId = res[1];
        if (domainType && domainId) {
          return _emberData.default.PromiseObject.create({
            promise: _this.store.findRecord(domainType, domainId)
          });
        } else {
          var auth = Ember.get(_this, 'authorization');
          return _emberData.default.PromiseObject.create({
            promise: Ember.get(auth, 'hasAuthLoaded').then(function () {
              return Ember.get(auth, 'session.currentUser');
            }).then(function (user) {
              if (user.isSuperUser && user.selectedDomain === '') {
                return {
                  featureEnabled: function featureEnabled() {
                    return true;
                  }
                };
              }
              return _this.store.findRecord(user.selectedDomain, user.selectedDomainId);
            })
          });
        }
      });
    }),
    isEnabled: Ember.computed('store', 'authorization', 'flagName', function () {
      var flag = Ember.get(this, 'flagName');
      return _emberData.default.PromiseObject.create({
        promise: Ember.get(this, 'domain').then(function (domain) {
          return domain.featureEnabled(flag);
        }).catch(function () {
          return false;
        })
      });
    })
  });
  IfFeatureEnabledComponent.reopenClass({
    positionalParams: ['flagName']
  });
  var _default = IfFeatureEnabledComponent;
  _exports.default = _default;
});