define("tmp-for-all/mixins/routes/edit-worksite/base-mixin", ["exports", "tmp-for-all/mixins/company-search", "tmp-for-all/helpers/permission/edit-worksite"], function (_exports, _companySearch, _editWorksite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    RSVP = Ember.RSVP;

  /**
   * Base mixin standardizes common actions (like cleanup, saving, and transitioning)
   * shared by all pages in the worksite create/edit wizard.
   * This mixin should not be used outside of the worksite create/edit pages.
   * Any methods and actions here should be considered defaults and may be overridden
   *
   * Notes:
   * - This mixin must know where to find the worksite instance that is being created or edited.
   *  If the mixed-in route has a model that is not simply a worksite instance
   *  (e.g. `model = {worksite: <Worksite>}` instead of `model = <Worksite>`)
   *  then you should override the `worksite` attribute with a dot.path that points to a worksite instance
   *  (e.g. `worksite: computed.alias('controller.model.worksite')`
   */
  var _default = Ember.Mixin.create(_companySearch.default, {
    modal: Ember.inject.service(),
    authorization: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    worksite: computed.alias('controller.model'),
    relationsAreDirty: computed(function () {
      return false;
    }),
    // just a default for mixed-in routes to override
    dataIsDirty: computed.or('worksite.hasChanges', 'relationsAreDirty'),
    saveSuccessMessage: 'Worksite has been updated successfully.',
    saveErrorMessage: 'There was a problem updating this Worksite',
    afterModel: function afterModel(model) {
      var _this = this;
      if (get(this, 'routeName').indexOf('edit') === -1) {
        var authManager = get(this, 'authManager');
        return get(this, 'authorization').checkAllowableNavigation(this, 'createDraftWorksite', get(authManager, 'currentUser.domain')).catch(function () {
          return _this.replaceWith('index');
        });
      } else {
        var worksite = get(model, 'worksite') || model; // ownership vs other pages have different model structures.
        return get(this, 'authorization').checkAllowableNavigation(this, (0, _editWorksite.editWorksite)([get(worksite, 'type')]), worksite).catch(function () {
          _this.replaceWith('index');
          return RSVP.Promise.reject();
        });
      }
    },
    openModal: function openModal(worksite, transition) {
      var _this2 = this;
      this.set('modal.options', {
        title: 'Discard Changes?',
        text: 'You have made unsaved changes to this worksite',
        confirmButton: 'Discard',
        confirmAction: function confirmAction() {
          RSVP.resolve(_this2.cleanup()).then(function () {
            _this2.get('modal').toggle();
            transition.retry();
          });
        }
      });
      this.get('modal').toggle();
    },
    transition: function transition(worksite, transitionPath) {
      return this.transitionTo(transitionPath, get(worksite, 'id'));
    },
    save: function save() {
      var worksite = get(this, 'worksite');
      return worksite.save();
    },
    saveAndTransition: function saveAndTransition(worksite, transitionPath) {
      var _this3 = this;
      return this.save().then(function () {
        get(_this3, 'flashMessages').success(get(_this3, 'saveSuccessMessage'));
        if (transitionPath) {
          _this3.transition(worksite, transitionPath);
        }
      }).catch(function (err) {
        var msg = typeof err === 'string' ? err : get(_this3, 'saveErrorMessage');
        get(_this3, 'flashMessages').danger(msg);
      });
    },
    cleanup: function cleanup() {
      var worksite = get(this, 'worksite');
      if (worksite) {
        worksite.destroyOrRollback();
      }
    },
    actions: {
      setUpdateTmps: function setUpdateTmps(bool) {
        this.set(this, 'updateTmps', bool);
      },
      finishDetails: function finishDetails(worksite) {
        this.saveAndTransition(worksite, get(this, 'finishedTarget'));
      },
      finish: function finish(worksite) {
        this.saveAndTransition(worksite, get(this, 'finishedTarget'));
      },
      next: function next(worksite) {
        this.saveAndTransition(worksite, get(this, 'nextStep'));
      },
      nextGlobalPermit: function nextGlobalPermit(worksite) {
        this.saveAndTransition(worksite, get(this, 'nextStepGlobalPermit'));
      },
      saveProgress: function saveProgress(worksite) {
        return this.saveAndTransition(worksite);
      },
      saveAndReturn: function saveAndReturn(worksite) {
        return this.saveAndTransition(worksite, "".concat(get(worksite, 'type'), ".view"));
      },
      willTransition: function willTransition(transition) {
        if (get(this, 'dataIsDirty')) {
          transition.abort();
          this.openModal(get(this, 'worksite'), transition);
        }
      }
    }
  });
  _exports.default = _default;
});