define("tmp-for-all/routes/worksite-submission/view", ["exports", "tmp-for-all/mixins/action-create-tmp-draft", "tmp-for-all/mixins/routes/add-comment-route-mixin", "tmp-for-all/mixins/routes/claim-worksite-mixin", "tmp-for-all/mixins/routes/delete-draft-tmp", "tmp-for-all/mixins/routes/add-auditor-mixin", "tmp-for-all/utils/conditions-util"], function (_exports, _actionCreateTmpDraft, _addCommentRouteMixin, _claimWorksiteMixin, _deleteDraftTmp, _addAuditorMixin, _conditionsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set;
  var _default = Ember.Route.extend(_actionCreateTmpDraft.default, _addCommentRouteMixin.default, _claimWorksiteMixin.default, _deleteDraftTmp.default, _addAuditorMixin.default, {
    modal: Ember.inject.service(),
    routeNavigator: Ember.inject.service(),
    model: function model() {
      var _this = this;
      var worksite = this.modelFor('worksite-submission');
      var jurisdiction = get(worksite, 'jurisdiction');
      var conditionGroups = jurisdiction.then(function (jurisdiction) {
        if (jurisdiction) {
          var conditionGroupQuery = {
            filter: {
              where: {
                isArchived: false,
                conditionType: 'worksite',
                jurisdictionId: get(jurisdiction, 'id')
              },
              include: 'conditions'
            }
          };
          return _this.store.query('condition-group', conditionGroupQuery);
        }
      });
      var selectedConditions = (0, _conditionsUtil.getSortedSelectedConditions)(worksite, 'acceptanceConditions');
      var rcaUsers = this.getRcaUsers(worksite);
      var rcaAreas = jurisdiction.then(function (jurisdiction) {
        if (jurisdiction) {
          return _this.store.query('jurisdiction-area', {
            filter: {
              where: {
                jurisdictionId: get(jurisdiction, 'id')
              }
            }
          });
        }
      });
      return RSVP.hash({
        worksite: worksite,
        conditionGroups: conditionGroups,
        selectedConditions: selectedConditions,
        rcaUsers: rcaUsers,
        rcaAreas: rcaAreas
      });
    },
    afterModel: function afterModel(model) {
      get(this, 'routeNavigator').setWayPoint(this, model.worksite);
    },
    warnLegacyData: function warnLegacyData(success) {
      var _this2 = this;
      this.set('modal.options', {
        title: 'Legacy Data Warning',
        text: 'This worksite extent contains legacy data. In order to make updates, the current extent will be cleared and will need to be redrawn. Do you want to proceed?',
        confirmButton: 'Create Revision',
        confirmAction: function confirmAction() {
          RSVP.resolve().then(function () {
            _this2.get('modal').toggle();
            success();
          });
        }
      });
      this.get('modal').toggle();
    },
    createDraft: function createDraft(worksite) {
      var _this3 = this;
      return worksite.createDraft().then(function () {
        return get(_this3, 'flashMessages').success('Worksite draft revision has been created.');
      }).then(function () {
        return _this3.transitionTo('worksite-draft.view', worksite.id);
      }).catch(function (err) {
        return get(_this3, 'flashMessages').danger(err.message);
      });
    },
    actions: {
      createDraft: function createDraft(worksite) {
        var _this4 = this;
        var worksiteLocation = get(worksite, 'location');
        if (worksiteLocation && get(worksiteLocation, 'type') !== 'Polygon' && !get(worksite, 'isGlobalPermit')) {
          this.warnLegacyData(function () {
            _this4.createDraft(worksite);
          });
        } else {
          this.createDraft(worksite);
        }
      },
      declineWorksite: function declineWorksite(worksite, commentText) {
        var _this5 = this;
        worksite.decline(commentText).then(function () {
          return get(_this5, 'flashMessages').success('Worksite has been declined.');
        }).then(function () {
          return _this5.refresh();
        }).catch(function (err) {
          return get(_this5, 'flashMessages').danger(err.message);
        });
      },
      saveCustomCondition: function saveCustomCondition(worksite, rcaId, text) {
        var _this6 = this;
        this.store.findRecord('jurisdiction', rcaId).then(function (jurisdiction) {
          var customCondition = _this6.store.createRecord('custom-condition', {
            worksiteSubmission: worksite,
            jurisdiction: jurisdiction,
            text: text
          });
          get(worksite, 'customConditions').pushObject(customCondition);
          customCondition.save();
        });
      },
      removeCustomCondition: function removeCustomCondition(worksite, customCondition) {
        get(worksite, 'customConditions').removeObject(customCondition);
        customCondition.destroyRecord();
      },
      addCondition: function addCondition(condition) {
        get(this, 'controller.model.selectedConditions').pushObject(condition);
      },
      removeCondition: function removeCondition(condition) {
        get(this, 'controller.model.selectedConditions').removeObject(condition);
      },
      saveWorksiteConditions: function saveWorksiteConditions(worksite, selectedConditions, rcaId) {
        var _this7 = this;
        worksite.saveConditions(selectedConditions, rcaId).then(function () {
          return worksite.save();
        }).then(function () {
          return get(_this7, 'flashMessages').success('Worksite conditions updated successfully');
        }).catch(function (err) {
          return get(_this7, 'flashMessages').danger(err.message);
        });
      },
      reviewWorksite: function reviewWorksite(worksite, description, comment) {
        var _this8 = this;
        return worksite.review(description, comment).then(function () {
          return get(_this8, 'flashMessages').success('Worksite review added.');
        }).catch(function (err) {
          return get(_this8, 'flashMessages').danger(err.message);
        });
      },
      issueAccessPermit: function issueAccessPermit(worksite, description, comment, selectedConditions, rcaId) {
        var _this9 = this;
        return worksite.saveConditions(selectedConditions, rcaId).then(function () {
          return worksite.accept(description, comment);
        }).then(function () {
          return get(_this9, 'flashMessages').success('Worksite access permit granted.');
        }).then(function () {
          return _this9.transitionTo('worksite.view', worksite.id);
        }).catch(function (err) {
          return get(_this9, 'flashMessages').danger(err.message);
        });
      },
      transitionToExtentEdit: function transitionToExtentEdit(worksite) {
        var route = get(worksite, 'type') + '.edit.extent';
        this.transitionTo(route);
      },
      setRCAArea: function setRCAArea(area, worksite) {
        set(worksite, 'jurisdictionArea', area);
        return worksite.save();
      }
    }
  });
  _exports.default = _default;
});