define("tmp-for-all/app", ["exports", "tmp-for-all/resolver", "ember-load-initializers", "tmp-for-all/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin", "tmp-for-all/models/custom-inflector-rules"], function (_exports, _resolver, _emberLoadInitializers, _environment, _authenticatedRouteMixin, _customInflectorRules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  // Add any environment variables passed from the server into the config.
  // See public/app/index.html and src/server.ts for how these are
  // injected. Removes it off the global object once it's in config so that
  // nowhere in the app relies on it.
  _environment.default.ENV_VARS = window.ENV_VARS || {};
  delete window.ENV_VARS;
  _environment.default.supportEmail = _environment.default.ENV_VARS.SUPPORT_EMAIL;
  if (_environment.default.ENV_VARS.MAPBOX_TOKEN) {
    _environment.default.mapboxToken = _environment.default.ENV_VARS.MAPBOX_TOKEN;
  }
  _environment.default.linzToken = _environment.default.ENV_VARS.LINZ_API_TOKEN;
  _environment.default.osrmServiceUrl = _environment.default.ENV_VARS.OSRM_SERVICE_URL;
  _environment.default.googleTagManagerKey = _environment.default.ENV_VARS.GOOGLE_TAGMANAGER_KEY;
  if (_environment.default.ENV_VARS.SERVER_ADDRESS) {
    _environment.default.apiHost = _environment.default.ENV_VARS.SERVER_ADDRESS;
    _environment.default.apiPath = "".concat(_environment.default.apiHost, "/").concat(_environment.default.apiNamespace);
  } else {
    _environment.default.apiPath = "/".concat(_environment.default.apiNamespace);
  }
  if (_environment.default.ENV_VARS.OSM_SERVER_ADDRESS) {
    _environment.default.osmHost = _environment.default.ENV_VARS.OSM_SERVER_ADDRESS;
    _environment.default.osmPath = "".concat(_environment.default.osmHost, "/").concat(_environment.default.osmNamespace);
  } else {
    _environment.default.osmPath = "/".concat(_environment.default.osmNamespace);
  }
  if (_environment.default.ENV_VARS.MWS_AUDITS_PREFIX) {
    _environment.default.mwsAuditsPrefix = _environment.default.ENV_VARS.MWS_AUDITS_PREFIX;
  } else {
    _environment.default.mwsAuditsPrefix = '/audits';
  }
  if (_environment.default.ENV_VARS.MWS_FEES_PREFIX) {
    _environment.default.mwsFeesPrefix = _environment.default.ENV_VARS.MWS_FEES_PREFIX;
  } else {
    _environment.default.mwsFeesPrefix = '/fees';
  }
  if (_environment.default.ENV_VARS.MWS_APPLY_PREFIX) {
    _environment.default.mwsApplyPrefix = _environment.default.ENV_VARS.MWS_APPLY_PREFIX;
  } else {
    _environment.default.mwsApplyPrefix = '/apply';
  }
  if (_environment.default.ENV_VARS.MWS_SEARCH_PREFIX) {
    _environment.default.mwsSearchPrefix = _environment.default.ENV_VARS.MWS_SEARCH_PREFIX;
  } else {
    _environment.default.mwsSearchPrefix = '/search';
  }
  if (_environment.default.environment !== 'test') {
    if (_environment.default.ENV_VARS.RAYGUN_APIKEY) {
      _environment.default.raygun = {
        apiKey: _environment.default.ENV_VARS.RAYGUN_APIKEY,
        enabled: true,
        enablePulse: true,
        enableCrashReporting: true,
        enableUserTracking: true,
        saveIfOffline: true,
        beQuiet: true,
        options: {
          ignoreAjaxError: true,
          ignoreAjaxAbort: true,
          ignore3rdPartyErrors: false,
          disableErrorTracking: false,
          debugMode: true
        }
      };
    } else {
      _environment.default.raygun = {
        enabled: false,
        apiKey: 'None'
      };
    }
  }
  if (_environment.default.environment === 'production') {
    _environment.default.APP.INSPECTLET_WID = _environment.default.ENV_VARS.INSTPECTLET_WID;
  }
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  // Catching errors when no Raygun configured
  if (!_environment.default.raygun.enabled) {
    Ember.onerror = function (error) {
      console.log('Error', error);
    };
  }
  Ember.UnprotectedRoute = Ember.Route;
  Ember.Route = Ember.Route.extend(_authenticatedRouteMixin.default);
  var _default = App;
  _exports.default = _default;
});