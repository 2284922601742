define("tmp-for-all/routes/company-admin/invite-user", ["exports", "tmp-for-all/mixins/company-search", "tmp-for-all/models/permission"], function (_exports, _companySearch, _permission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set,
    setProperties = Ember.setProperties;
  var _default = Ember.Route.extend(_companySearch.default, {
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'authorization.hasAuthLoaded').then(function () {
        return get(_this, 'session.currentUser');
      }).then(function (user) {
        set(_this, 'currentUser', user);
        return get(_this, 'authorization').checkAllowableNavigation(_this, 'editUsers', {
          modelName: get(user, 'selectedDomain'),
          modelId: get(user, 'selectedDomainId')
        });
      }).catch(function () {
        return _this.router.replaceWith('index');
      });
    },
    model: function model() {
      var selectedDomain = get(this, 'currentUser.selectedDomain') || 'company';
      var selectedDomainId = get(this, 'currentUser.selectedDomainId');
      var isSuperUser = get(this, 'currentUser.isAdmin');
      return RSVP.hash({
        user: get(this, 'store').createRecord('tmp-user'),
        jurisdictions: isSuperUser ? this.store.findAll('jurisdiction') : null,
        roles: this.store.query('permission', _permission.default.roleFilter),
        isSuperUser: isSuperUser,
        selectedDomain: selectedDomain,
        selectedDomainId: selectedDomainId
      });
    },
    afterModel: function afterModel(model) {
      var defaultRole = model.selectedDomain === 'company' ? 'contractor' : 'coordinator';
      var role = model.roles.findBy('name', defaultRole);
      var permissionAssignment = get(this, 'store').createRecord('permission-assignment', {
        permission: role,
        user: model.user,
        domainType: model.selectedDomain,
        domainId: model.selectedDomainId
      });
      get(permissionAssignment, 'user.permissionAssignments').pushObject(permissionAssignment);
    },
    actions: {
      saveUser: function saveUser(user, org, role) {
        var _this2 = this;
        var newUser = get(user, 'isNew');
        var selectedDomain = get(this, 'controller.model.selectedDomain');
        var selectedDomainId = get(this, 'controller.model.selectedDomainId') || get(org, 'id');

        // new users: set selected domain and id and add meta to send invite email
        if (newUser) {
          setProperties(user, {
            selectedDomain: selectedDomain,
            selectedDomainId: selectedDomainId
          });
          user.set('meta', {
            creationType: 'invite'
          });
        }
        var savePromise = newUser ? user.save() : RSVP.resolve();
        savePromise.then(function () {
          return get(user, 'permissionAssignments');
        })
        // save all the newly assigned permissions
        .then(function (permissionAssignments) {
          return Promise.all(permissionAssignments.filter(function (a) {
            return get(a, 'isNew');
          }).map(function (newPermissionAssignment) {
            return newPermissionAssignment.save();
          }));
        }).then(function () {
          if (!newUser) user.inviteUser(selectedDomainId);
        }).then(function () {
          var successMessage = newUser ? 'A new User has been invited to join MyWorksites' : 'An existing user has been added to your organisation';
          get(_this2, 'flashMessages').success(successMessage);
          _this2.transitionTo(get(_this2, 'currentUser.domain') ? 'company-admin.edit-users' : 'users.list');
        }).catch(function () {
          get(_this2, 'flashMessages').danger('There was an error saving the user.');
        });
      },
      addRole: function addRole(org) {
        var orgId = org ? get(org, 'id') : null;
        var permissionAssignment = get(this, 'store').createRecord('permission-assignment', {
          user: get(this, 'controller.model.user'),
          domainType: get(this, 'controller.model.selectedDomain'),
          domainId: get(this, 'controller.model.selectedDomainId') || orgId
        });
        get(this, 'controller.model.user.permissionAssignments').pushObject(permissionAssignment);
      }
    },
    willTransition: function willTransition() {
      var user = get(this, 'controller.model.user');
      if (user) user.destroyOrRollback();
    }
  });
  _exports.default = _default;
});