define("tmp-for-all/routes/worksite/view", ["exports", "tmp-for-all/mixins/action-create-tmp-draft", "tmp-for-all/mixins/routes/add-comment-route-mixin", "tmp-for-all/mixins/routes/claim-worksite-mixin", "tmp-for-all/mixins/routes/delete-draft-tmp", "tmp-for-all/utils/conditions-util"], function (_exports, _actionCreateTmpDraft, _addCommentRouteMixin, _claimWorksiteMixin, _deleteDraftTmp, _conditionsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Route.extend(_actionCreateTmpDraft.default, _addCommentRouteMixin.default, _claimWorksiteMixin.default, _deleteDraftTmp.default, {
    modal: Ember.inject.service(),
    routeNavigator: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model() {
      var _this = this;
      // eagerly fetch tmp relationships
      var worksite = this.modelFor('worksite');
      var conditionGroupQuery = {
        filter: {
          where: {
            isArchived: false,
            conditionType: 'worksite'
          },
          include: ['conditions']
        }
      };
      var rcaAreas = get(worksite, 'jurisdiction').then(function (jurisdiction) {
        return _this.store.query('jurisdiction-area', {
          filter: {
            where: {
              jurisdictionId: get(jurisdiction, 'id')
            }
          }
        });
      });
      return RSVP.hash({
        worksite: worksite,
        rcaUsers: this.getRcaUsers(worksite),
        worksiteMeta: get(worksite, 'worksiteMeta'),
        notices: this.store.query('notice', {
          filter: {
            where: {
              worksiteId: worksite.id
            }
          }
        }),
        conditionGroups: this.store.query('condition-group', {
          conditionGroupQuery: conditionGroupQuery
        }),
        allConditionGroups: this.store.query('condition-group', {
          filter: {
            include: 'conditions'
          }
        }),
        selectedConditions: (0, _conditionsUtil.getSortedSelectedConditions)(worksite, 'acceptanceConditions'),
        rcaAreas: rcaAreas,
        globalPermitChildren: get(this, 'ajax').getGlobalPermitChildren(worksite.id).then(function (childWorksites) {
          return _this.relationsForWorksites(childWorksites, childWorksites.map(function (cw) {
            return cw.id;
          }));
        })
      });
    },
    afterModel: function afterModel(model) {
      get(this, 'routeNavigator').setWayPoint(this, model.worksite);
    },
    // Adds related tmp details and layoutcodes to site specific worksite hashes for display on the Global Permit worksite view
    relationsForWorksites: function relationsForWorksites(worksites, worksiteIds) {
      if (!worksites.length) return [];

      // TODO: issue with global worksite - 85418. Need to come up with better solution to load TMPS and layouts for huge list of worksites

      if (worksiteIds.length <= 5) {
        return RSVP.hash({
          worksites: worksites,
          tmps: this.store.query('tmp', {
            filter: {
              where: {
                worksiteId: {
                  inq: worksiteIds
                }
              },
              include: ['layouts']
            }
          }),
          tmpSubmissions: this.store.query('tmpSubmission', {
            filter: {
              where: {
                worksiteId: {
                  inq: worksiteIds
                }
              },
              include: ['layouts']
            }
          }),
          tmpDrafts: this.store.query('tmpDraft', {
            filter: {
              where: {
                worksiteId: {
                  inq: worksiteIds
                }
              },
              include: ['layouts']
            }
          })
        }).then(function (hash) {
          var allTmps = hash.tmps.toArray().concat(hash.tmpSubmissions.toArray(), hash.tmpDrafts.toArray());
          return hash.worksites.map(function (w) {
            return {
              worksite: w,
              tmps: allTmps.filter(function (t) {
                return parseInt(w.id) === get(t, 'worksiteId');
              })
            };
          });
        });
      } else {
        return RSVP.hash({
          worksites: worksites
        }).then(function (hash) {
          return hash.worksites.map(function (w) {
            return {
              worksite: w
            };
          });
        });
      }
    },
    warnLegacyData: function warnLegacyData(success) {
      var _this2 = this;
      this.set('modal.options', {
        title: 'Legacy Data Warning',
        text: 'This worksite extent contains legacy data. In order to make updates, the current extent will be cleared and will need to be redrawn. Do you want to proceed?',
        confirmButton: 'Create Revision',
        confirmAction: function confirmAction() {
          RSVP.resolve().then(function () {
            _this2.get('modal').toggle();
            success();
          });
        }
      });
      this.get('modal').toggle();
    },
    createDraft: function createDraft(worksite) {
      var _this3 = this;
      return worksite.createDraft().then(function () {
        return get(_this3, 'flashMessages').success('Worksite draft revision has been created.');
      }).then(function () {
        return _this3.transitionTo('worksite-draft.view', worksite.id);
      }).catch(function (err) {
        return get(_this3, 'flashMessages').danger(err.message);
      });
    },
    actions: {
      createDraft: function createDraft(worksite) {
        var _this4 = this;
        var worksiteLocation = get(worksite, 'location');
        if (worksiteLocation && get(worksiteLocation, 'type') !== 'Polygon' && !get(worksite, 'isGlobalPermit')) {
          this.warnLegacyData(function () {
            _this4.createDraft(worksite);
          });
        } else {
          this.createDraft(worksite);
        }
      },
      declineWorksite: function declineWorksite(worksite, commentText) {
        var _this5 = this;
        worksite.decline(commentText).then(function () {
          return get(_this5, 'flashMessages').success('Worksite has been declined.');
        }).then(function () {
          return _this5.refresh();
        }).catch(function (err) {
          return get(_this5, 'flashMessages').danger(err.message);
        });
      },
      startWork: function startWork() {
        this.replaceWith('worksite.work.start');
      },
      completeWork: function completeWork() {
        this.replaceWith('worksite.work.complete');
      },
      completeMaintenance: function completeMaintenance() {
        this.replaceWith('worksite.work.complete-maintenance');
      },
      modifyWarranty: function modifyWarranty() {
        this.replaceWith('worksite.work.modify-warranty');
      },
      stopWork: function stopWork() {
        this.replaceWith('worksite.work.stop');
      },
      nonConformance: function nonConformance() {
        this.replaceWith('worksite.work.non-conformance');
      },
      clearNotice: function clearNotice(notice) {
        this.replaceWith('worksite.notice.resolve', notice);
      },
      rejectNotice: function rejectNotice(notice) {
        this.replaceWith('worksite.notice.reject', notice);
      },
      transitionToExtentEdit: function transitionToExtentEdit(worksite) {
        var route = get(worksite, 'type') + '.edit.extent';
        this.transitionTo(route);
      },
      setRCAArea: function setRCAArea(area, worksite) {
        set(worksite, 'jurisdictionArea', area);
        return worksite.save();
      }
    }
  });
  _exports.default = _default;
});