define("tmp-for-all/routes/worksite-draft/view", ["exports", "tmp-for-all/mixins/action-create-tmp-draft", "tmp-for-all/mixins/routes/delete-draft-worksite", "tmp-for-all/mixins/routes/delete-draft-tmp"], function (_exports, _actionCreateTmpDraft, _deleteDraftWorksite, _deleteDraftTmp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    $ = Ember.$,
    computed = Ember.computed;
  var _default = Ember.Route.extend(_actionCreateTmpDraft.default, _deleteDraftWorksite.default, _deleteDraftTmp.default, {
    ajax: Ember.inject.service(),
    authorization: Ember.inject.service(),
    routeNavigator: Ember.inject.service(),
    worksite: computed.alias('controller.model'),
    afterModel: function afterModel(model) {
      var _this = this;
      var getSubmissionErrors = function getSubmissionErrors() {
        get(_this, 'routeNavigator').setWayPoint(_this, model);
        return get(_this, 'ajax').worksiteAction(model, 'submission-errors').then(function (errors) {
          set(model, 'submissionErrors', errors);
        });
      };

      // We don't need to check permissions on published worksites
      if (get(model, 'isPublished')) {
        return getSubmissionErrors();
      } else {
        return get(this, 'authorization').checkAllowableNavigation(this, 'viewPrivateWorksiteDraft', model).then(getSubmissionErrors, function () {
          return _this.replaceWith('index');
        }).catch(function () {
          return _this.replaceWith('index');
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (!get(model, 'submissionErrors.length')) {
        set(this, 'controller.displayErrorModal', false);
      }
      set(this, 'controller.displaySubmissionModal', false);
      set(this, 'controller.displayPurchaseOrderFeeModal', false);
      set(this, 'controller.submissionConfirmed', false);
    },
    scrollToFeeEstimate: function scrollToFeeEstimate() {
      var offset = $('.fee-display-section').offset().top;
      var pageEl = $('html, body');
      var events = 'scroll mousedown wheel DOMMouseScroll mousewheel touchmove';
      pageEl.on(events, function () {
        return pageEl.stop();
      });
      pageEl.animate({
        scrollTop: "".concat(offset, "px")
      }, 800, function () {
        return pageEl.off(events);
      });
    },
    postDeleteDraftWorksiteHook: function postDeleteDraftWorksiteHook() {
      // Redirect to the list of drafts.
      this.transitionTo('worksites.drafts');
    },
    actions: {
      toggleErrorModal: function toggleErrorModal() {
        get(this, 'controller').toggleProperty('displayErrorModal');
      },
      toggleSubmissionModal: function toggleSubmissionModal() {
        get(this, 'controller').toggleProperty('displaySubmissionModal');
      },
      togglePurchaseOrderFeeModal: function togglePurchaseOrderFeeModal() {
        get(this, 'controller').toggleProperty('displayPurchaseOrderFeeModal');
      },
      submitWorksite: function submitWorksite(worksite) {
        var _this2 = this;
        return worksite.submit().then(function () {
          return set(_this2, 'controller.submissionConfirmed', true);
        }).catch(function (err) {
          get(_this2, 'flashMessages').danger(err.message);
          set(_this2, 'worksite.submissionErrors', err.errors);
        });
      },
      togglePublishState: function togglePublishState(worksiteDraft) {
        var _this3 = this;
        var originalState = get(worksiteDraft, 'isPublished');
        set(worksiteDraft, 'isPublished', !originalState);
        return worksiteDraft.save().then(function () {
          if (!originalState) {
            // bool value
            get(_this3, 'flashMessages').success('Success! This draft is now publicly visible.');
          } else {
            get(_this3, 'flashMessages').success('Success! This draft is no longer publicly visible.');
          }
        }).catch(function (err) {
          // revert
          set(worksiteDraft, 'isPublished', originalState);
          get(_this3, 'flashMessages').danger(err.message);
        });
      },
      getFeeEstimate: function getFeeEstimate(worksite) {
        var _this4 = this;
        return worksite.getFeeEstimate().then(function () {
          Ember.run.schedule('afterRender', _this4, function () {
            return _this4.scrollToFeeEstimate();
          });
        });
      },
      willTransition: function willTransition() {
        set(this, 'worksite.estimatedFees', []);
      },
      transitionToExtentEdit: function transitionToExtentEdit(worksite) {
        var route = get(worksite, 'type') + '.edit.extent';
        this.transitionTo(route);
      }
    }
  });
  _exports.default = _default;
});