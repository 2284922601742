define("tmp-for-all/components/user-form/component", ["exports", "tmp-for-all/config/environment", "tmp-for-all/utils/route-helpers"], function (_exports, _environment, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set,
    isEmpty = Ember.isEmpty;
  var _default = Ember.Component.extend({
    modal: Ember.inject.service(),
    store: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // Set the organisation if the user was invited by a company or jurisdiction (for the disabled select element)
      var comps = get(this, 'assignedCompanies') || [];
      var jurs = get(this, 'assignedJurisdictions') || [];
      var assignedOrg = comps.concat(jurs)[0];
      if (assignedOrg) {
        var displayName = get(assignedOrg, 'displayName') ? get(assignedOrg, 'displayName') : get(assignedOrg, 'name');
        set(assignedOrg, 'name', displayName);
        set(this, 'currentCompany', assignedOrg);
      } else {
        set(this, 'newCompany', {});
      }
    },
    termsOfUseUrl: _environment.default.termsOfUseUrl,
    privacyPolicyUrl: _environment.default.privacyPolicyUrl,
    termsAndPrivacyAgreeLabel: computed('termsOfUseUrl', 'privacyPolicyUrl', function () {
      return "I agree to the <a target=\"_blank\" href=\"".concat(get(this, 'termsOfUseUrl'), "\">Terms of Use</a> and <a target=\"_blank\" href=\"").concat(get(this, 'privacyPolicyUrl'), "\">Privacy Policy</a>").htmlSafe();
    }),
    termsAndCondBool: computed('user.termsAndConditionsAccepted', function () {
      return !!get(this, 'user.termsAndConditionsAccepted');
    }),
    supportEmail: _environment.default.supportEmail,
    archiveButtonText: computed('user.isArchived', function () {
      return get(this, 'user.isArchived') ? 'Unarchive' : 'Archive';
    }),
    belongsToCompany: true,
    organistionOptions: [{
      name: 'belongsToCompany',
      value: true,
      label: 'Yes, a Registered Company, trust, overseas company, government entity, alliance or consortium.',
      checked: true
    }, {
      name: 'belongsToCompany',
      value: false,
      label: "No, I'm a Private Individual"
    }],
    hasNzbn: false,
    nzbnOptions: [{
      name: 'hasNzbn',
      value: false,
      label: 'No',
      checked: true
    }, {
      name: 'hasNzbn',
      value: true,
      label: 'Yes',
      checked: false
    }],
    currentUserCompany: computed('currentUser', function () {
      // The company they are acting under
      var u = get(this, 'currentUser');
      var c = get(this, 'currentUser.companies');
      var company = c.find(function (co) {
        var id = get(co, 'id');
        return id === u.selectedDomainId;
      });
      return company ? [company] : [];
    }),
    currentUserJurisdiction: computed('currentUser', function () {
      if (get(this, 'isSuperUser')) {
        return this.store.findAll('jurisdiction');
      } else {
        // The jurisdiction they are acting under
        var u = get(this, 'currentUser');
        var j = get(this, 'currentUser.jurisdictions');
        var rca = j.find(function (co) {
          var id = get(co, 'id');
          return id === u.selectedDomainId;
        });
        return rca ? [rca] : [];
      }
    }),
    editablePermissionAssignments: computed('user.permissionAssignments', function () {
      var unfiltered = get(this, 'user.permissionAssignments') || [];
      if (get(this, 'isSuperUser')) return unfiltered;
      var domain = get(this, 'currentUser.selectedDomain');
      var domainId = get(this, 'currentUser.selectedDomainId');
      return unfiltered.filter(function (assignment) {
        if (!get(assignment, 'domainId')) return true;
        return get(assignment, 'domainType') === domain && get(assignment, 'domainId') === domainId;
      });
    }),
    notAssigned: computed('user.permissionAssignments.@each.isDeleted', 'user.permissionAssignments.@each.domainId', function () {
      var permissionAssigments = get(this, 'user.permissionAssignments');
      if (!permissionAssigments) return false;
      var assignments = permissionAssigments.filter(function (assignment) {
        return !get(assignment, 'isDeleted') && get(assignment, 'domainId');
      });
      return assignments.length === 0;
    }),
    unscopedAssignments: computed('user.permissionAssignments.@each.domainType', 'user.permissionAssignments.@each.permission', function () {
      var permissionAssignments = get(this, 'user.permissionAssignments');
      return permissionAssignments.filter(function (assignment) {
        return assignment.domainType === null;
      });
    }),
    saveDisabled: computed('activatingUser', 'notAssigned', 'disabled', 'belongsToCompany', 'physicalAddress', 'currentCompany', 'newCompany', 'newCompany.{name,physicalAddress}', 'user.{preferredName,lastName,mobile,email,password,termsAndConditionsAccepted}', function () {
      if (get(this, 'disabled')) return true;
      if (get(this, 'activatingUser')) {
        if (get(this, 'isPrivateIndividual')) {
          if (isEmpty(get(this, 'physicalAddress'))) {
            return true;
          }
        } else {
          if (isEmpty(get(this, 'currentCompany'))) {
            return true;
          }
        }
        return isEmpty(get(this, 'user.preferredName')) || isEmpty(get(this, 'user.lastName')) || isEmpty(get(this, 'user.mobile')) || isEmpty(get(this, 'user.email')) || isEmpty(get(this, 'user.password')) || isEmpty(get(this, 'user.termsAndConditionsAccepted'));
      } else {
        // When editing an existing user, if they should belong to a company then we check 'notAssigned', which
        // will be true if the user has no permission assignments to any organisations.
        if (get(this, 'belongsToCompany')) {
          return get(this, 'notAssigned');
        } else {
          if (isEmpty(get(this, 'physicalAddress'))) return true;
        }
      }
    }),
    showYourOrgSelector: computed.alias('activatingUser').readOnly(),
    showLoginDetails: computed('activatingUser', 'isSuperUser', function () {
      return get(this, 'activatingUser') || get(this, 'isSuperUser');
    }).readOnly(),
    showOrgsAndPrivateIndividuals: computed('activatingUser', 'userIsJurisdictionDomain', function () {
      return !(get(this, 'activatingUser') || get(this, 'userIsJurisdictionDomain'));
    }).readOnly(),
    showJurisdictions: computed('activatingUser', 'userIsCompanyDomain', function () {
      return !(get(this, 'activatingUser') || get(this, 'userIsCompanyDomain'));
    }).readOnly(),
    showNotAssignedError: computed('activatingUser', 'notAssigned', function () {
      return !get(this, 'activatingUser') && get(this, 'notAssigned');
    }).readOnly(),
    showUnscopedRoles: computed('activatingUser', function () {
      return !get(this, 'activatingUser');
    }).readOnly(),
    showArchiveLink: computed('activatingUser', 'user.isNew', function () {
      return !(get(this, 'activatingUser') || get(this, 'user.isNew'));
    }).readOnly(),
    isPrivateIndividual: computed.not('belongsToCompany'),
    userIsJurisdictionDomain: computed.equal('currentUser.selectedDomain', 'jurisdiction'),
    userIsCompanyDomain: computed.equal('currentUser.selectedDomain', 'company'),
    companyAssignments: computed.filter('assignments', function (a) {
      return get(a, 'domainType') === 'company';
    }),
    jurisdictionAssignments: computed.filter('assignments', function (a) {
      return get(a, 'domainType') === 'jurisdiction';
    }),
    actions: {
      addNewCompany: function addNewCompany() {
        this.replaceWith((0, _routeHelpers.getChildRouteName)(get(this, 'routeName'), 'new-company'));
      },
      saveUser: function saveUser(user) {
        var currentCompany = get(this, 'currentCompany');
        var newCompany = get(this, 'newCompany');
        if (currentCompany && get(currentCompany, 'modelName') === 'company' || get(this, 'isPrivateIndividual')) {
          get(this, 'saveUser')(user, get(this, 'isPrivateIndividual'), get(this, 'physicalAddress'), get(this, 'postalAddress'), currentCompany);
        } else if (!get(this, 'isPrivateIndividual') && newCompany) {
          get(this, 'saveUser')(user, false, newCompany.physicalAddress, get(this, 'postalAddress'), newCompany);
        } else {
          get(this, 'saveUser')(user);
        }
      },
      toggleArchiveState: function toggleArchiveState(user) {
        get(this, 'toggleArchiveState')(user);
      },
      updateTsAndCs: function updateTsAndCs(checked) {
        set(this, 'user.termsAndConditionsAccepted', checked ? new Date() : null);
      },
      openExitModal: function openExitModal(user) {
        var _this = this;
        set(this, 'modal.options', {
          title: 'Exit registration process?',
          html: "<p>Unfortunately, we can't accept your registration without your acceptance of the Terms of Use and Privacy Policy.</p>\n               <p>If you have any specific questions or concerns about the Terms of Use or Privacy Policy, please send them to our support team by emailing ".concat(get(this, 'supportEmail'), ".</p>\n               <p>By clicking \"Confirm\" we will exit you from the registration process and delete the personal information submitted to this point from the system. By clicking \"Cancel\", you can return to the \"Activate Account\" process and accept the Terms of Use and Privacy Policy to continue your registration.</p>"),
          confirmButton: 'Confirm',
          confirmAction: function confirmAction() {
            get(_this, 'deleteUser')(user);
            get(_this, 'modal').toggle();
          },
          cancelAction: function cancelAction() {
            get(_this, 'modal').toggle();
          }
        });
        get(this, 'modal').toggle();
      },
      resendInvite: function resendInvite(user) {
        get(this, 'resendInvite')(user);
      },
      becomeUser: function becomeUser(user) {
        if (get(this, 'becomeUser')) {
          get(this, 'becomeUser')(user);
        }
      },
      createCompanySuggestion: function createCompanySuggestion(term) {
        return 'Can\'t find your company? Click here to add it';
      },
      registeredCompanySelected: function registeredCompanySelected(company) {
        set(this, 'newCompany.nzbn', company.nzbn);
        set(this, 'newCompany.name', company.name);
        set(this, 'newCompany.physicalAddress', company.physicalAddress);
      }
    }
  });
  _exports.default = _default;
});