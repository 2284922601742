define("tmp-for-all/templates/worksite/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YYedtmXq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"worksite-view\",null,[[\"worksite\",\"rcaUsers\",\"claimWorksite\",\"notices\",\"clearNotice\",\"rejectNotice\",\"declineWorksite\",\"reviseTmp\",\"conditionGroups\",\"allConditionGroups\",\"selectedConditions\",\"createNewDraftTmp\",\"deleteDraftTmp\",\"addComment\",\"startWork\",\"nonConformance\",\"completeWork\",\"completeMaintenance\",\"modifyWarranty\",\"stopWork\",\"createDraft\",\"onEditExtentClicked\",\"rcaAreas\",\"setRCAArea\",\"globalPermitChildren\"],[[23,[\"model\",\"worksite\"]],[23,[\"model\",\"rcaUsers\"]],[27,\"route-action\",[\"claimWorksite\"],null],[23,[\"model\",\"notices\"]],[27,\"route-action\",[\"clearNotice\"],null],[27,\"route-action\",[\"rejectNotice\"],null],[27,\"route-action\",[\"declineWorksite\"],null],[27,\"route-action\",[\"reviseTmp\"],null],[23,[\"model\",\"conditionGroups\"]],[23,[\"model\",\"allConditionGroups\"]],[23,[\"model\",\"selectedConditions\"]],[27,\"route-action\",[\"createNewDraftTmp\"],null],[27,\"route-action\",[\"deleteDraftTmp\"],null],[27,\"route-action\",[\"addComment\"],null],[27,\"route-action\",[\"startWork\"],null],[27,\"route-action\",[\"nonConformance\"],null],[27,\"route-action\",[\"completeWork\"],null],[27,\"route-action\",[\"completeMaintenance\"],null],[27,\"route-action\",[\"modifyWarranty\"],null],[27,\"route-action\",[\"stopWork\"],null],[27,\"route-action\",[\"createDraft\"],null],[27,\"route-action\",[\"transitionToExtentEdit\"],null],[23,[\"model\",\"rcaAreas\"]],[27,\"route-action\",[\"setRCAArea\"],null],[23,[\"model\",\"globalPermitChildren\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksite/view.hbs"
    }
  });
  _exports.default = _default;
});