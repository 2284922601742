define("tmp-for-all/templates/worksite-draft/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "s90Buxrv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"worksite-view\",null,[[\"worksite\",\"submissionErrors\",\"submitWorksite\",\"deleteDraftWorksite\",\"deleteDraftWorksitePending\",\"togglePublishState\",\"toggleErrorModal\",\"displayErrorModal\",\"toggleSubmissionModal\",\"togglePurchaseOrderFeeModal\",\"displaySubmissionModal\",\"displayPurchaseOrderFeeModal\",\"reviseTmp\",\"createNewDraftTmp\",\"deleteDraftTmp\",\"getFeeEstimate\",\"submissionConfirmed\",\"onEditExtentClicked\"],[[23,[\"model\"]],[23,[\"model\",\"submissionErrors\"]],[27,\"route-action\",[\"submitWorksite\"],null],[27,\"route-action\",[\"deleteDraftWorksite\"],null],[23,[\"deleteDraftWorksitePending\"]],[27,\"route-action\",[\"togglePublishState\"],null],[27,\"route-action\",[\"toggleErrorModal\"],null],[23,[\"displayErrorModal\"]],[27,\"route-action\",[\"toggleSubmissionModal\"],null],[27,\"route-action\",[\"togglePurchaseOrderFeeModal\"],null],[23,[\"displaySubmissionModal\"]],[23,[\"displayPurchaseOrderFeeModal\"]],[27,\"route-action\",[\"reviseTmp\"],null],[27,\"route-action\",[\"createNewDraftTmp\"],null],[27,\"route-action\",[\"deleteDraftTmp\"],null],[27,\"route-action\",[\"getFeeEstimate\"],null],[23,[\"submissionConfirmed\"]],[27,\"route-action\",[\"transitionToExtentEdit\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksite-draft/view.hbs"
    }
  });
  _exports.default = _default;
});